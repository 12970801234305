import request from '@/utils/request'


// 查询用户举报分类列表
export function listUserReportType(query) {
  return request({
    url: '/platform/user-report-type/list',
    method: 'get',
    params: query
  })
}

// 查询用户举报分类分页
export function pageUserReportType(query) {
  return request({
    url: '/platform/user-report-type/page',
    method: 'get',
    params: query
  })
}

// 查询用户举报分类详细
export function getUserReportType(data) {
  return request({
    url: '/platform/user-report-type/detail',
    method: 'get',
    params: data
  })
}

// 新增用户举报分类
export function addUserReportType(data) {
  return request({
    url: '/platform/user-report-type/add',
    method: 'post',
    data: data
  })
}

// 修改用户举报分类
export function updateUserReportType(data) {
  return request({
    url: '/platform/user-report-type/edit',
    method: 'post',
    data: data
  })
}

// 删除用户举报分类
export function delUserReportType(data) {
  return request({
    url: '/platform/user-report-type/delete',
    method: 'post',
    data: data
  })
}
